import React from "react"
import PropTypes from "prop-types"
import "./youtubePlayer.css"

const YoutubePlayer = ({ embedId, width }) => (
  <div width={width}>
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      style={{ borderRadius: 8 }}
    />
  </div>
)

YoutubePlayer.propTypes = {
  embedId: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
}

export default YoutubePlayer
