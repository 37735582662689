import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Stack from "@mui/material/Stack"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import MuiLink from "@mui/material/Link"
import makeStyles from "@mui/styles/makeStyles"
import Tab from "@mui/material/Tab"
import TabContext from "@mui/lab/TabContext"
import TabList from "@mui/lab/TabList"
import TabPanel from "@mui/lab/TabPanel"
import Box from "@mui/material/Box"
import { CopyBlock } from "react-code-blocks"

import { RepositoryMetrics } from "repository-metrics"

import MenuBookIcon from "@mui/icons-material/MenuBook"
import GitHubIcon from "@mui/icons-material/GitHub"
import ChatIcon from "@mui/icons-material/Chat"
import YouTubeIcon from "@mui/icons-material/YouTube"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import Header from "../../../components/header"
import YoutubePlayer from "../../../components/youtubePlayer"
import ImageWavesLight from "../../../images/1000_F_504089586_pUxO4wojC4gBCsrpdNNUOHVewDQQhNcr.jpg"
import ContentBox from "../../../components/contentBox"
import AccordionBox from "../../../components/accordionBox"

const IndexBlog = () => (
  <Layout>
    <Seo title="Start EN" description="Start EN" keywords="Start" />
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{
        background: `#555 url(${ImageWavesLight}) no-repeat fixed left center`,
        backgroundSize: "cover",
        overflowX: "hidden",
      }}
    >
      <Header />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          marginTop={10}
          marginBottom={20}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            {/* <Link to="/de/">DE</Link> */}
            <Typography variant="h1" fontSize="5rem">
              FOR GAMERS
            </Typography>
            <Typography variant="body" fontSize="2rem" textAlign="center">
              How the Restreamer is used by gamers.
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </Stack>
    <Stack
      direction="column"
      justifyContent="start-flex"
      alignItems="start-flex"
      spacing={4}
      marginBottom={11}
      style={{ borderTop: "10px solid #333" }}
    >
      <Container maxWidth="xl">
        <Typography
          variant="h2"
          fontSize="2.5rem"
          color="#333"
          fontWeight={900}
          marginTop={10}
          marginBottom={5}
        >
          An alternative to Restream.io?
        </Typography>
        <Typography
          variant="body"
          fontSize="1.2rem"
          color="#333"
          fontWeight={300}
        >
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.
        </Typography>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          marginTop={5}
          marginBottom={5}
        >
          <YoutubePlayer embedId="V5Q3yLeSCWY" />
        </Stack>
        <Typography
          variant="body"
          fontSize="1.2rem"
          color="#333"
          fontWeight={300}
        >
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.
        </Typography>
      </Container>
    </Stack>
  </Layout>
)

export default IndexBlog
